import React from 'react';
import PostCode from '../components/general/PostCodeStep';
import { StepUrl } from '../utils/enums';
import SEOLayout from '../components/layouts/SEOLayout';

const Index = (props: any) => {
  const { location } = props;
  const queryParams = new URLSearchParams(location.search);
  const localityId = queryParams.get('locality_id');

  return (
    <SEOLayout>
      <PostCode
        userType="business" 
        pageUrl={StepUrl.URL_POSTCODE_BUSINESS}
        localityId={localityId}
      />
    </SEOLayout>
  );
};

export default Index;
